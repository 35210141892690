import React from "react";
import { useOverrides, Override, SocialMedia } from "@quarkly/components";
import { Image, LinkBox, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"margin": "0 0 0 0",
	"padding": "16px 0 16px 0",
	"box-sizing": "border-box",
	"quarkly-title": "Footer",
	"justify-content": "center"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"grid-gap": "32px",
			"width": "100%",
			"grid-template-columns": "repeat(2, 1fr)",
			"sm-grid-gap": "16px",
			"display": "grid"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"align-items": "center",
			"sm-flex-wrap": "wrap"
		}
	},
	"linkBox": {
		"kind": "LinkBox",
		"props": {
			"width": "200px",
			"height": "40px",
			"href": "/index"
		}
	},
	"image": {
		"kind": "Image",
		"props": {
			"width": "200px",
			"height": "50px",
			"src": "https://uploads.quarkly.io/6617165c6bbd200023699c9b/images/Logo_Black.jpg?v=2024-04-12T14:40:28.861Z",
			"flex": "0 0 auto"
		}
	},
	"socialMedia": {
		"kind": "SocialMedia",
		"props": {
			"facebook": "",
			"twitter": "",
			"youtube": "",
			"justify-content": "flex-end"
		}
	},
	"socialMediaOverride": {
		"kind": "Override",
		"props": {
			"slot": "link",
			"background": "none",
			"border-radius": "50%"
		}
	},
	"socialMediaOverride1": {
		"kind": "Override",
		"props": {
			"slot": "icon",
			"color": "--dark"
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Override
			slot="SectionContent"
			width="100%"
			md-margin="0px 16px 0px 16px"
			min-width="auto"
			margin="0px 32px 0px 32px"
		/>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<LinkBox {...override("linkBox")}>
					<Image {...override("image")} />
				</LinkBox>
			</Box>
			<SocialMedia {...override("socialMedia")}>
				<Override {...override("socialMediaOverride")} />
				<Override {...override("socialMediaOverride1")} />
			</SocialMedia>
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;