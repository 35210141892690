import React from "react";
import theme from "theme";
import { Theme, Link, Text, Box, Em, Span, Image, Button, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"certification"} />
		<Helmet>
			<title>
				Certifications | Paralux
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6617165c6bbd200023699c9b/images/favicon-32x32.png?v=2024-04-15T01:05:03.390Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6617165c6bbd200023699c9b/images/apple-touch-icon.png?v=2024-04-15T01:02:43.525Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6617165c6bbd200023699c9b/images/apple-touch-icon.png?v=2024-04-15T01:02:43.525Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6617165c6bbd200023699c9b/images/apple-touch-icon.png?v=2024-04-15T01:02:43.525Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6617165c6bbd200023699c9b/images/apple-touch-icon.png?v=2024-04-15T01:02:43.525Z"} />
		</Helmet>
        <Components.NavBarHeader>
	<Override
		slot="button"
		href="/SignUp"
		display="inline-block"
		width="fit-content"
		justify-self="end"
	/>
</Components.NavBarHeader>
<Box
	min-width="100px"
	min-height="100px"
	display="block"
	margin="20px 0px 0px 0px"
	sm-height="fit-content"
	sm-min-height="fit-content"
>
	<Box
		min-width="100px"
		min-height="100px"
		margin="0px 10px 0px 10px"
		sm-height="fit-content"
		sm-min-height="fit-content"
		sm-margin="0px 10px 25px 10px"
	>
		<Text
			margin="0px 0px 36px 0px"
			color="#191E22"
			font="--headline2"
			lg-text-align="center"
			sm-font="--headline3"
			sm-margin="0px 0px 20px 0px"
			lg-margin="0px 0px 18px 0px"
			md-text-align="left"
			text-align="center"
		>
			Your Certifications
		</Text>
	</Box>
</Box>
<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-15">
	<Override
		slot="SectionContent"
		flex-wrap="wrap"
		flex-direction="row"
		display="flex"
		order="-1"
	/>
	<Box
		sm-padding="0px 0px 0px 0px"
		padding="16px 16px 0px 16px"
		lg-order="1"
		width="50%"
		align-items="flex-start"
		lg-align-items="center"
		lg-margin="0px 0px 0px 0px"
		sm-margin="0px 0px 0px 0px"
		display="flex"
		flex-direction="column"
		lg-width="100%"
		justify-content="space-between"
	>
		<Box display="flex" flex-direction="column">
			<Text
				margin="0px 0px 36px 0px"
				color="--darkL2"
				font="--headline2"
				lg-text-align="center"
				sm-font="--headline3"
				sm-margin="0px 0px 20px 0px"
				lg-margin="0px 0px 18px 0px"
				md-text-align="left"
			>
				Rolex Submariner Date 
				<br />
				16610LV
			</Text>
			<Text
				margin="0px 0px 0px 0px"
				color="--darkL2"
				font="--base"
				lg-text-align="center"
				md-text-align="left"
			>
				<br />
				Certification Number: 000135v51
				<br />
				Status: 
				<Em
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					Active
				</Em>
				 
				<br />
				Authentication: 
				<Span
					overflow-wrap="normal"
					word-break="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					user-select="auto"
					pointer-events="auto"
				>
					<Em>
						Paralux Guarantee
					</Em>
					<br />
					<br />
					Make: Rolex
					<br />
					Model: Submariner Date Kermit 16610LV 
				</Span>
			</Text>
		</Box>
		<Box
			width="100%"
			display="flex"
			sm-flex-wrap="wrap"
			sm-display="grid"
			sm-justify-items="center"
		>
			<Box
				display="flex"
				width="25%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				sm-width="50%"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 125% 0px"
				>
					<Image
						width="100%"
						left={0}
						src="https://uploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%203.38.17%20PM.png?v=2024-04-21T21:28:55.521Z"
						object-fit="cover"
						position="absolute"
						display="block"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%203.38.17%20PM.png?v=2024-04-21T21%3A28%3A55.521Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%203.38.17%20PM.png?v=2024-04-21T21%3A28%3A55.521Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%203.38.17%20PM.png?v=2024-04-21T21%3A28%3A55.521Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%203.38.17%20PM.png?v=2024-04-21T21%3A28%3A55.521Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%203.38.17%20PM.png?v=2024-04-21T21%3A28%3A55.521Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%203.38.17%20PM.png?v=2024-04-21T21%3A28%3A55.521Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%203.38.17%20PM.png?v=2024-04-21T21%3A28%3A55.521Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				width="25%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				sm-width="50%"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					position="relative"
					padding="0px 0px 125% 0px"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
				>
					<Image
						display="block"
						width="100%"
						top="auto"
						right={0}
						position="absolute"
						object-fit="cover"
						left={0}
						bottom="0px"
						min-height="100%"
						src="https://uploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%203.38.29%20PM.png?v=2024-04-21T21:29:27.651Z"
						srcSet="https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%203.38.29%20PM.png?v=2024-04-21T21%3A29%3A27.651Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%203.38.29%20PM.png?v=2024-04-21T21%3A29%3A27.651Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%203.38.29%20PM.png?v=2024-04-21T21%3A29%3A27.651Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%203.38.29%20PM.png?v=2024-04-21T21%3A29%3A27.651Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%203.38.29%20PM.png?v=2024-04-21T21%3A29%3A27.651Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%203.38.29%20PM.png?v=2024-04-21T21%3A29%3A27.651Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%203.38.29%20PM.png?v=2024-04-21T21%3A29%3A27.651Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				sm-width="50%"
				display="flex"
				width="25%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					height="auto"
					transform="translateY(0px)"
					padding="0px 0px 125% 0px"
					width="100%"
				>
					<Image
						object-fit="cover"
						width="100%"
						right={0}
						bottom="0px"
						min-height="100%"
						src="https://uploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%203.38.37%20PM.png?v=2024-04-21T21:29:42.069Z"
						position="absolute"
						display="block"
						top="auto"
						left={0}
						srcSet="https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%203.38.37%20PM.png?v=2024-04-21T21%3A29%3A42.069Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%203.38.37%20PM.png?v=2024-04-21T21%3A29%3A42.069Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%203.38.37%20PM.png?v=2024-04-21T21%3A29%3A42.069Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%203.38.37%20PM.png?v=2024-04-21T21%3A29%3A42.069Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%203.38.37%20PM.png?v=2024-04-21T21%3A29%3A42.069Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%203.38.37%20PM.png?v=2024-04-21T21%3A29%3A42.069Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%203.38.37%20PM.png?v=2024-04-21T21%3A29%3A42.069Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="25%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				sm-width="50%"
			>
				<Box
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					width="100%"
					height="auto"
					overflow-y="hidden"
					padding="0px 0px 125% 0px"
					overflow-x="hidden"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
				>
					<Image
						object-fit="cover"
						position="absolute"
						width="100%"
						left={0}
						bottom="0px"
						src="https://uploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%205.30.06%20PM.png?v=2024-04-21T21:30:25.633Z"
						display="block"
						top="auto"
						right={0}
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%205.30.06%20PM.png?v=2024-04-21T21%3A30%3A25.633Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%205.30.06%20PM.png?v=2024-04-21T21%3A30%3A25.633Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%205.30.06%20PM.png?v=2024-04-21T21%3A30%3A25.633Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%205.30.06%20PM.png?v=2024-04-21T21%3A30%3A25.633Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%205.30.06%20PM.png?v=2024-04-21T21%3A30%3A25.633Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%205.30.06%20PM.png?v=2024-04-21T21%3A30%3A25.633Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-21%20at%205.30.06%20PM.png?v=2024-04-21T21%3A30%3A25.633Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Box>
		<Box
			min-width="100px"
			min-height="40px"
			order="0"
			display="flex"
			width="100%"
			align-self="auto"
			height="auto"
			text-align="center"
			position="static"
			justify-content="space-around"
			flex="0 1 auto"
			overflow-x="visible"
			flex-direction="row"
			flex-wrap="wrap"
			sm-display="grid"
		>
			<Button width="auto" margin="10px 10px 0px 0px" sm-flex="0 1 auto">
				Verify
			</Button>
			<Button margin="10px 10px 0px 0px">
				More Information
			</Button>
			<Button margin="10px 10px 0px 0px">
				Lock
			</Button>
			<Button margin="10px 10px 0px 0px">
				Transfer
			</Button>
		</Box>
	</Box>
	<Box
		margin="0px 0px 0px 0px"
		padding="16px 16px 16px 16px"
		sm-padding="0px 0px 0px 0px"
		sm-margin="0px 0px 30px 0px"
		flex-direction="column"
		display="flex"
		width="40%"
		lg-width="100%"
	>
		<Box
			transition="transform 0.2s ease-in-out 0s"
			hover-transform="translateY(-10px)"
			width="100%"
			position="relative"
			transform="translateY(0px)"
			padding="0px 0px 125% 0px"
			height="auto"
			overflow-x="hidden"
			overflow-y="hidden"
			sm-display="block"
			sm-align-self="auto"
			sm-height="fit-content"
			sm-min-height="fit-content"
		>
			<Image
				src="https://uploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen_Shot_2024-04-12_at_12.52.12_AM-removebg-preview.png?v=2024-04-14T19:53:01.809Z"
				object-fit="scale-down"
				left={0}
				right={0}
				min-height="100%"
				position="absolute"
				display="block"
				width="100%"
				top="auto"
				bottom="0px"
				height="50%"
				srcSet="https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen_Shot_2024-04-12_at_12.52.12_AM-removebg-preview.png?v=2024-04-14T19%3A53%3A01.809Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen_Shot_2024-04-12_at_12.52.12_AM-removebg-preview.png?v=2024-04-14T19%3A53%3A01.809Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen_Shot_2024-04-12_at_12.52.12_AM-removebg-preview.png?v=2024-04-14T19%3A53%3A01.809Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen_Shot_2024-04-12_at_12.52.12_AM-removebg-preview.png?v=2024-04-14T19%3A53%3A01.809Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen_Shot_2024-04-12_at_12.52.12_AM-removebg-preview.png?v=2024-04-14T19%3A53%3A01.809Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen_Shot_2024-04-12_at_12.52.12_AM-removebg-preview.png?v=2024-04-14T19%3A53%3A01.809Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen_Shot_2024-04-12_at_12.52.12_AM-removebg-preview.png?v=2024-04-14T19%3A53%3A01.809Z&quality=85&w=3200 3200w"
				sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
				sm-position="absolute"
			/>
		</Box>
	</Box>
</Section>
<Components.Footer />
	</Theme>;
});