import React, { useState, useEffect} from 'react';

import theme from "theme";
import { Theme, Link, Text, Section, Box, Image, Em, Button, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
import { DiBootstrap, DiBingSmall, DiCssTricks, DiEnvato, DiGithubBadge } from "react-icons/di";

import axios from 'axios';

//Styling 
import signUpPageStyle from '../style/signupPageStyle';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => signUpPageStyle(theme));


const IndexPage = () => {
	const classes = useStyles();
	const [email, setEmail] = useState('');
	const [submitted, setSubmitted] = useState(false);


	const handleSubmit = async (e) => {
		e.preventDefault();
		console.log('Email', email);
		try {
			// Add data to Firebase database
			const type = "waitlist";
			const date = new Date();
			const month = date.getMonth() + 1;
			const day = date.getDate();
			const year = date.getFullYear();

			const formattedDate = `${month}-${day}-${year}`;
			const currDate = new Date().toLocaleDateString();
			console.log(currDate);
			console.log(formattedDate);
			const data = {
				email: email,
				date: currDate
			}
			const sanitizedEmail = email.replace(/[.#$[\]]/g, '_');
			const key = `${type}::${sanitizedEmail}`;
			// const key = formattedDate;
			// await set(ref(database, 'waitlist/' + key), data);
			// console.log('Data from Firebase:', data);
			setEmail('');
			setSubmitted(true);

			//MailJet called from backend server 
			await axios.post('http://localhost:8000/api/addToWaitlist', { email: email });

		} catch (error) {
			console.error('Error adding document: ', error);
		}
		// Clear form fields and set submitted flag
		// setName('');
		setEmail('');
		setSubmitted(true);
	  };


// export default (() => {
	return (
	<Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | Paralux
			</title>
			<meta name={"description"} content={"It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference."} />
			<meta property={"og:title"} content={"Home | Website Example"} />
			<meta property={"og:description"} content={"It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/website-example-quarkly.png?v=2020-11-05T19:48:01.806Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6617165c6bbd200023699c9b/images/favicon-32x32.png?v=2024-04-15T01:05:03.390Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6617165c6bbd200023699c9b/images/apple-touch-icon.png?v=2024-04-15T01:02:43.525Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6617165c6bbd200023699c9b/images/apple-touch-icon.png?v=2024-04-15T01:02:43.525Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6617165c6bbd200023699c9b/images/apple-touch-icon.png?v=2024-04-15T01:02:43.525Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6617165c6bbd200023699c9b/images/apple-touch-icon.png?v=2024-04-15T01:02:43.525Z"} />
		</Helmet>
		<Components.NavBarHeader>
			<Override
				slot="button"
				href="/SignUp"
				display="inline-block"
				width="fit-content"
				justify-self="end"
			/>
		</Components.NavBarHeader>
		<Section
			lg-padding="25px 0 25px 0"
			sm-padding="0px 0 25px 0"
			justify-content="center"
			padding="25px 0 75px 0"
			sm-align-items="center"
			sm-justify-content="center"
			quarkly-title="Hero"
		>
			<Override
				slot="SectionContent"
				md-margin="0px 16px 0px 16px"
				width="100%"
				background="url(https://images.unsplash.com/photo-1661030419605-3d4ca9cda193?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) 0% 0%/100% repeat,linear-gradient(180deg,rgba(155, 108, 252, 0.15) 0%,transparent 100%) 0 0 no-repeat"
				height="620px"
				justify-content="center"
				sm-width="100%"
				min-width="auto"
				margin="0px 32px 0px 32px"
				align-items="center"
				lg-height="520px"
				md-height="420px"
				md-padding="0px 24px 0px 24px"
				sm-flex="0 1 auto"
				sm-align-self="auto"
				sm-display="flex"
				sm-background="url(https://images.unsplash.com/photo-1661030419605-3d4ca9cda193?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) 0% 0%/cover repeat"
			/>
			<Text
				font="--headline1"
				margin="16px 0px 0px 0px"
				sm-text-align="center"
				sm-width="80%"
				lg-text-align="center"
				lg-font="--headline2"
				color="--light"
			>
				Paralux
			</Text>
			<Text
				sm-text-align="center"
				sm-width="80%"
				opacity="0.7"
				md-text-align="center"
				font="--lead"
				color="--light"
				margin="10px 0px 35px 0px"
				border-width="0px"
				border-color="#000000"
				border-style="solid"
				text-shadow="1px 1px 2px #464646"
			>
				Elevate your watch game with Paralux: where luxury meets trust.
			</Text>
			<div className={classes.signUpFormContainer}>
					{submitted ? (
								<p className={classes.formDescription}>Thank you for joining the waitlist!</p>
							) : (
					<form onSubmit={handleSubmit} className={classes.form}>
						<div className={classes.formGroup}>
							<input 
							type="email" 
							value={email} 
							onChange={(e) => setEmail(e.target.value)} 
							placeholder="Email"
							required 
							className={classes.formInput}
							/>
						</div>
					<Button type="submit" className={classes.formButton}>Join Waitlist</Button>
					</form>
						)}
				</div>
			{/* <Link
				text-decoration-line="initial"
				color="--darkL2"
				padding="12px 24px 12px 24px"
				letter-spacing="0.5px"
				transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
				href="/SignUp"
				background="--color-light"
				font="--base"
				hover-transform="translateY(-4px)"
			>
				Join Waitlist
			</Link> */}
		</Section>
		<Section
			box-sizing="border-box"
			lg-padding="50px 30px 50px 30px"
			id="mission"
			padding="90px 0px 100px 0px"
			quarkly-title="About"
			border-color="--color-lightD2"
			border-style="solid"
			border-width="1px 0px 1px 0px"
			sm-padding="24px 0 24px 0"
			background="--color-lightD1"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				md-margin="0px 16px 0px 16px"
				align-items="center"
				width="100%"
				min-width="auto"
				margin="0px 32px 0px 32px"
			/>
			<Text
				text-align="center"
				font="--base"
				opacity="0.6"
				letter-spacing="1px"
				margin="0px 0px 10px 0px"
				lg-margin="0px 0px 6px 0px"
				quarkly-title="Title"
				text-transform="uppercase"
				color="--dark"
				lg-text-align="center"
			>
				Our Mission
			</Text>
			<Text
				letter-spacing="1px"
				color="--dark"
				text-align="center"
				width="85%"
				lg-font="--lead"
				sm-font="--base"
				font="normal 500 28px/1.2 'AvenirNextCyrRegular', sans-serif"
				margin="0px 0px 0px 0px"
			>
				Creating the market standard for watch authentication{" "}
			</Text>
		</Section>
		<Section
			md-padding="25px 0px 25px 0px"
			justify-content="center"
			padding="70px 0 70px 0"
			quarkly-title="Info"
			lg-padding="50px 0px 50px 0px"
			width="inherit"
			height="inherit"
		>
			<Override
				slot="SectionContent"
				width="100%"
				min-width="auto"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
			/>
			<Box
				lg-grid-template-columns="1fr"
				display="grid"
				grid-template-columns="2fr 3fr"
				grid-gap="64px"
				xl-grid-gap="32px"
				md-grid-template-columns="1fr"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Text
						quarkly-title="Title"
						text-transform="uppercase"
						lg-text-align="center"
						margin="0px 0px 10px 0px"
						letter-spacing="1px"
						color="--dark"
						opacity="0.6"
						lg-margin="0px 0px 6px 0px"
						font="--base"
					>
						What
					</Text>
					<Text
						font="--headline2"
						margin="0px 0px 28px 0px"
						color="--dark"
						lg-text-align="center"
						lg-margin="0px 0px 18px 0px"
						sm-text-align="left"
						sm-font="--headline3"
						md-font="--headline3"
					>
						Paralux
					</Text>
					<Text
						font="--lead"
						margin="0px 0px 20px 0px"
						color="--darkL2"
						opacity="0.6"
						lg-text-align="left"
						lg-margin="0px 0px 18px 0px"
						sm-font="--base"
					>
						Your go-to destination for luxury watch buying and selling. With full authentication, guarantees, and warranties, shop with confidence and trust in every timepiece.
					</Text>
					<Link
						margin="9px 0px 0px 0px"
						hover-transform="translateY(-4px)"
						text-decoration-line="initial"
						padding="8px 18px 8px 18px"
						font="--base"
						transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
						text-align="center"
						href="/about"
						color="--light"
						letter-spacing="0.5px"
						background="--color-primary"
					>
						Read More
					</Link>
				</Box>
				<Box
					padding="0px 0px 60% 0px"
					hover-background="--color-lightD2 url(https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-illustration-sea-first.svg?v=2020-11-06T16:37:39.391Z) center center/110% no-repeat"
					md-order="-1"
					width="100%"
					background="url(https://uploads.quarkly.io/6617165c6bbd200023699c9b/images/Watch_card.jpeg?v=2024-04-15T01:08:26.862Z) 0% 0%/100%,--color-lightD2"
					margin="0px 0px 0px 0px"
					lg-order="-1"
					justify-self="auto"
					display="block"
					height="0px"
					pointer-events="none"
					transition="none 0s ease 0s"
				/>
			</Box>
			<Box
				md-margin="44px 0px 0px 0px"
				lg-grid-template-columns="1fr"
				display="grid"
				grid-template-columns="3fr 2fr"
				grid-gap="64px"
				margin="96px 0px 0px 0px"
				xl-grid-gap="32px"
				md-grid-template-columns="1fr"
				lg-margin="64px 0px 0px 0px"
			>
				<Box
					margin="0px 0px 0px 0px"
					padding="0px 0px 60% 0px"
					height="0px"
					hover-background="--color-lightD2 url(https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-illustration-sea-second.svg?v=2020-11-06T17:14:59.136Z) center center/110% no-repeat"
					transition="background --transitionDuration-normal --transitionTimingFunction-easeInOut 0s"
					width="100%"
					background="--color-lightD2 url(https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-illustration-sea-second.svg?v=2020-11-06T17:14:59.136Z) center center/100% no-repeat"
				>
					<Image
						src="https://uploads.quarkly.io/6617165c6bbd200023699c9b/images/examineWatch.webp?v=2024-04-12T04:55:27.391Z"
						display="block"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/examineWatch.webp?v=2024-04-12T04%3A55%3A27.391Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/examineWatch.webp?v=2024-04-12T04%3A55%3A27.391Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/examineWatch.webp?v=2024-04-12T04%3A55%3A27.391Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/examineWatch.webp?v=2024-04-12T04%3A55%3A27.391Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/examineWatch.webp?v=2024-04-12T04%3A55%3A27.391Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/examineWatch.webp?v=2024-04-12T04%3A55%3A27.391Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/examineWatch.webp?v=2024-04-12T04%3A55%3A27.391Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
				>
					<Text
						font="--base"
						letter-spacing="1px"
						quarkly-title="Title"
						margin="0px 0px 10px 0px"
						color="--dark"
						opacity="0.6"
						lg-text-align="center"
						lg-margin="0px 0px 6px 0px"
						text-transform="uppercase"
					>
						Team
					</Text>
					<Text
						md-font="--headline3"
						font="--headline2"
						margin="0px 0px 28px 0px"
						color="--dark"
						lg-text-align="center"
						lg-margin="0px 0px 18px 0px"
						sm-font="--headline3"
					>
						Who We Are
					</Text>
					<Text
						lg-margin="0px 0px 18px 0px"
						sm-font="--base"
						font="--lead"
						margin="0px 0px 20px 0px"
						color="--darkL2"
						opacity="0.6"
						lg-text-align="left"
					>
						Paralux is the leading authority in luxury watch authentication. Our team of certified specialists is dedicated to redefining trust and authenticity in the watch industry. With a focus on innovation and integrity, we're setting new standards for quality and reliability.
					</Text>
					<Link
						href="/SignUp"
						color="--light"
						padding="8px 18px 8px 18px"
						text-align="center"
						transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
						text-decoration-line="initial"
						font="--base"
						letter-spacing="0.5px"
						margin="9px 0px 0px 0px"
						background="--color-primary"
						hover-transform="translateY(-4px)"
					>
						Read More
					</Link>
				</Box>
			</Box>
			<Box
				md-margin="44px 0px 0px 0px"
				lg-grid-template-columns="1fr"
				display="grid"
				grid-template-columns="2fr 3fr"
				xl-grid-gap="32px"
				md-grid-template-columns="1fr"
				lg-margin="64px 0px 0px 0px"
				grid-gap="64px"
				margin="96px 0px 0px 0px"
			>
				<Box
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					min-width="100px"
					min-height="100px"
				>
					<Text
						lg-text-align="center"
						quarkly-title="Title"
						text-transform="uppercase"
						opacity="0.6"
						lg-margin="0px 0px 6px 0px"
						font="--base"
						margin="0px 0px 10px 0px"
						letter-spacing="1px"
						color="--dark"
					>
						expertise
					</Text>
					<Text
						font="--headline2"
						margin="0px 0px 28px 0px"
						color="--dark"
						lg-text-align="center"
						lg-margin="0px 0px 18px 0px"
						sm-font="--headline3"
						md-font="--headline3"
					>
						Why Choose Us
					</Text>
					<Text
						opacity="0.6"
						sm-text-align="left"
						lg-text-align="left"
						lg-margin="0px 0px 18px 0px"
						sm-font="--base"
						font="--lead"
						margin="0px 0px 20px 0px"
						color="--darkL2"
					>
						When you choose Paralux, you're choosing peace of mind. Our state-of-the-art marketplace ensures that every watch is 100% authentic, giving you confidence in your investment. With our rigorous authentication process and lifetime digital storage, you can trust Paralux to safeguard the integrity of your luxury timepieces.
						<br />
					</Text>
					<Link
						color="--light"
						text-align="center"
						background="--color-primary"
						transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
						hover-transform="translateY(-4px)"
						href="/SignUp"
						text-decoration-line="initial"
						padding="8px 18px 8px 18px"
						font="--base"
						letter-spacing="0.5px"
						margin="9px 0px 0px 0px"
					>
						Read More
					</Link>
				</Box>
				<Box
					hover-background="--color-lightD2 url(https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-illustration-sea-third.svg?v=2020-11-06T17:15:50.700Z) center center/110% no-repeat"
					lg-order="-1"
					margin="0px 0px 0px 0px"
					padding="0px 0px 60% 0px"
					height="0px"
					md-order="-1"
					width="100%"
					background="--color-lightD2 url(https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-illustration-sea-third.svg?v=2020-11-06T17:15:50.700Z) center center/100% no-repeat"
					transition="background --transitionDuration-normal --transitionTimingFunction-easeInOut 0s"
				>
					<Image
						src="https://images.unsplash.com/photo-1482954363933-4bed6bbea570?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
						display="block"
						width="100%"
						srcSet="https://images.unsplash.com/photo-1482954363933-4bed6bbea570?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1482954363933-4bed6bbea570?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1482954363933-4bed6bbea570?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1482954363933-4bed6bbea570?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1482954363933-4bed6bbea570?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1482954363933-4bed6bbea570?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1482954363933-4bed6bbea570?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
				</Box>
			</Box>
		</Section>
		<Section
			padding="100px 0px 100px 0px"
			quarkly-title="Works"
			box-sizing="border-box"
			lg-padding="50px 30px 50px 30px"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				width="100%"
				min-width="auto"
				margin="0px 32px 0px 32px"
				md-margin="0px 0px 0px 0px"
				align-items="center"
			/>
			<Text
				color="--dark"
				text-align="center"
				text-transform="uppercase"
				lg-text-align="center"
				letter-spacing="1px"
				margin="0px 0px 10px 0px"
				opacity="0.6"
				lg-margin="0px 0px 6px 0px"
				quarkly-title="Title"
				font="--base"
			>
				what we create
			</Text>
			<Text
				text-align="center"
				lg-text-align="center"
				md-margin="0px 0px 36px 0px"
				sm-font="--headline3"
				md-font="--headline3"
				font="--headline2"
				margin="0px 0px 48px 0px"
				color="--dark"
			>
				Trending
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				md-margin="0px 0px 50px 0px"
				grid-gap="0 35px"
				lg-grid-gap="16px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="#d9e9fa"
					border-radius="4px"
				>
					<Image
						src="https://uploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-11%20at%2011.53.00%20PM.png?v=2024-04-12T03:55:15.241Z"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
						srcSet="https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-11%20at%2011.53.00%20PM.png?v=2024-04-12T03%3A55%3A15.241Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-11%20at%2011.53.00%20PM.png?v=2024-04-12T03%3A55%3A15.241Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-11%20at%2011.53.00%20PM.png?v=2024-04-12T03%3A55%3A15.241Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-11%20at%2011.53.00%20PM.png?v=2024-04-12T03%3A55%3A15.241Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-11%20at%2011.53.00%20PM.png?v=2024-04-12T03%3A55%3A15.241Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-11%20at%2011.53.00%20PM.png?v=2024-04-12T03%3A55%3A15.241Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-11%20at%2011.53.00%20PM.png?v=2024-04-12T03%3A55%3A15.241Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						display="flex"
						flex-direction="column"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Rolex 1908
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							<Em
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								1908
							</Em>
							{" "}Platinum, ice blue, brown alligator leather strap.{" "}
							<br />
							39mm, polished finish, 2024
							<br />
							Ref: 52506
						</Text>
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
						>
							<Button background="--color-darkL1" border-radius="4px" href="/" margin="0px 10px 0px 0px">
								Read more
							</Button>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.5 --fontFamily-serifGaramond">
								$32,000
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-11%20at%2011.57.36%20PM.png?v=2024-04-12T03:57:59.640Z"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
						srcSet="https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-11%20at%2011.57.36%20PM.png?v=2024-04-12T03%3A57%3A59.640Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-11%20at%2011.57.36%20PM.png?v=2024-04-12T03%3A57%3A59.640Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-11%20at%2011.57.36%20PM.png?v=2024-04-12T03%3A57%3A59.640Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-11%20at%2011.57.36%20PM.png?v=2024-04-12T03%3A57%3A59.640Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-11%20at%2011.57.36%20PM.png?v=2024-04-12T03%3A57%3A59.640Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-11%20at%2011.57.36%20PM.png?v=2024-04-12T03%3A57%3A59.640Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-11%20at%2011.57.36%20PM.png?v=2024-04-12T03%3A57%3A59.640Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Audemars Piguet Royal Oak
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							Royal Oak Perpetual Calendar{" "}
							<br />
							Stainless Steel with a "
							<Em>
								Grande Tapisseerie
							</Em>
							" blue dial.{" "}
							<br />
							Ref: 26574ST.00.1220ST.03
						</Text>
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
						>
							<Button background="--color-darkL1" border-radius="4px" href="/" margin="0px 10px 0px 0px">
								Read more
							</Button>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.5 --fontFamily-serifGaramond">
								$109,300
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-11%20at%2011.54.27%20PM.png?v=2024-04-12T03:54:46.701Z"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
						srcSet="https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-11%20at%2011.54.27%20PM.png?v=2024-04-12T03%3A54%3A46.701Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-11%20at%2011.54.27%20PM.png?v=2024-04-12T03%3A54%3A46.701Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-11%20at%2011.54.27%20PM.png?v=2024-04-12T03%3A54%3A46.701Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-11%20at%2011.54.27%20PM.png?v=2024-04-12T03%3A54%3A46.701Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-11%20at%2011.54.27%20PM.png?v=2024-04-12T03%3A54%3A46.701Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-11%20at%2011.54.27%20PM.png?v=2024-04-12T03%3A54%3A46.701Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Screen%20Shot%202024-04-11%20at%2011.54.27%20PM.png?v=2024-04-12T03%3A54%3A46.701Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Rolex Day-Date
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							Oyster Perpetual{" "}
							<Em
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								user-select="auto"
								pointer-events="auto"
							>
								Day-Date
							</Em>
							<br />
							40mm, platinum with an ice-blue dial, fluted bezel, and a President bracelet, 2024{" "}
							<br />
							Ref: 228236
						</Text>
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
						>
							<Button background="--color-darkL1" border-radius="4px" href="/" margin="0px 10px 0px 0px">
								Read more
							</Button>
							<Text margin="0px 0px 0px 0px" font="normal 600 20px/1.5 --fontFamily-serifGaramond">
								$64,000
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
			<Link
				text-align="center"
				margin="48px 0px 0px 0px"
				hover-transform="translateY(-4px)"
				href="/SignUp"
				color="--light"
				padding="8px 18px 8px 18px"
				font="--base"
				transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
				md-margin="36px 0px 0px 0px"
				text-decoration-line="initial"
				letter-spacing="0.5px"
				background="--color-primary"
				lg-margin="24px 0px 0px 0px"
			>
				View More
			</Link>
		</Section>
		<Section
			padding="50px 0px 100px 0px"
			quarkly-title="Works"
			box-sizing="border-box"
			lg-padding="50px 30px 50px 30px"
			justify-content="center"
			sm-height="fit-content"
		>
			<Override
				slot="SectionContent"
				width="100%"
				min-width="auto"
				margin="0px 32px 0px 32px"
				md-margin="0px 0px 0px 0px"
				align-items="center"
				sm-height="fit-content"
			/>
			<Text
				color="--dark"
				text-align="center"
				text-transform="uppercase"
				lg-text-align="center"
				letter-spacing="1px"
				margin="0px 0px 10px 0px"
				opacity="0.6"
				lg-margin="0px 0px 6px 0px"
				quarkly-title="Title"
				font="--base"
			>
				what we create
			</Text>
			<Text
				text-align="center"
				lg-text-align="center"
				md-margin="0px 0px 36px 0px"
				sm-font="--headline3"
				md-font="--headline3"
				font="--headline2"
				margin="0px 0px 48px 0px"
				color="--dark"
			>
				Collections
			</Text>
			<Box
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				width="100%"
				display="grid"
				grid-gap="32px"
				grid-template-columns="repeat(3, 1fr)"
				height="60%"
				sm-height="fit-content"
				sm-grid-gap="36px"
			>
				<Box
					width="100%"
					background="--color-lightD2 url(https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-illustration-sea-first.svg?v=2020-11-06T16:37:39.391Z) center center/100% no-repeat"
					padding="0px 0px 60% 0px"
					hover-background="--color-lightD2 url(https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-illustration-sea-first.svg?v=2020-11-06T16:37:39.391Z) center center/110% no-repeat"
					transition="background --transitionDuration-normal --transitionTimingFunction-easeInOut 0s"
					height="38%"
				>
					<Image
						src="https://uploads.quarkly.io/6617165c6bbd200023699c9b/images/JustDropped.png?v=2024-04-12T03:28:11.726Z"
						display="block"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/JustDropped.png?v=2024-04-12T03%3A28%3A11.726Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/JustDropped.png?v=2024-04-12T03%3A28%3A11.726Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/JustDropped.png?v=2024-04-12T03%3A28%3A11.726Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/JustDropped.png?v=2024-04-12T03%3A28%3A11.726Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/JustDropped.png?v=2024-04-12T03%3A28%3A11.726Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/JustDropped.png?v=2024-04-12T03%3A28%3A11.726Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/JustDropped.png?v=2024-04-12T03%3A28%3A11.726Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
				</Box>
				<Box
					width="100%"
					padding="0px 0px 60% 0px"
					height="37%"
					hover-background="--color-lightD2 url(https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-illustration-sea-second.svg?v=2020-11-06T17:14:59.136Z) center center/110% no-repeat"
					transition="background --transitionDuration-normal --transitionTimingFunction-easeInOut 0s"
				>
					<Image
						src="https://uploads.quarkly.io/6617165c6bbd200023699c9b/images/HeavyHitters.png?v=2024-04-12T03:30:05.298Z"
						display="block"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/HeavyHitters.png?v=2024-04-12T03%3A30%3A05.298Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/HeavyHitters.png?v=2024-04-12T03%3A30%3A05.298Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/HeavyHitters.png?v=2024-04-12T03%3A30%3A05.298Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/HeavyHitters.png?v=2024-04-12T03%3A30%3A05.298Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/HeavyHitters.png?v=2024-04-12T03%3A30%3A05.298Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/HeavyHitters.png?v=2024-04-12T03%3A30%3A05.298Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/HeavyHitters.png?v=2024-04-12T03%3A30%3A05.298Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
				</Box>
				<Box
					width="100%"
					padding="0px 0px 60% 0px"
					height="38%"
					hover-background="--color-lightD2 url(https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-illustration-sea-third.svg?v=2020-11-06T17:15:50.700Z) center center/110% no-repeat"
					transition="background --transitionDuration-normal --transitionTimingFunction-easeInOut 0s"
				>
					<Image
						src="https://uploads.quarkly.io/6617165c6bbd200023699c9b/images/TwoTone.png?v=2024-04-12T03:30:13.362Z"
						display="block"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/TwoTone.png?v=2024-04-12T03%3A30%3A13.362Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/TwoTone.png?v=2024-04-12T03%3A30%3A13.362Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/TwoTone.png?v=2024-04-12T03%3A30%3A13.362Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/TwoTone.png?v=2024-04-12T03%3A30%3A13.362Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/TwoTone.png?v=2024-04-12T03%3A30%3A13.362Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/TwoTone.png?v=2024-04-12T03%3A30%3A13.362Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/TwoTone.png?v=2024-04-12T03%3A30%3A13.362Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Link
				text-align="center"
				margin="18px 0px 0px 0px"
				hover-transform="translateY(-4px)"
				href="/SignUp"
				color="--light"
				padding="8px 18px 8px 18px"
				font="--base"
				transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
				md-margin="36px 0px 0px 0px"
				text-decoration-line="initial"
				letter-spacing="0.5px"
				background="--color-primary"
				lg-margin="24px 0px 0px 0px"
			>
				View More
			</Link>
		</Section>
		<Section
			sm-padding="24px 0 24px 0"
			background="--color-lightD1"
			border-style="solid"
			box-sizing="border-box"
			justify-content="center"
			padding="90px 0px 100px 0px"
			border-color="--color-lightD2"
			border-width="1px 0px 1px 0px"
			lg-padding="50px 30px 50px 30px"
			quarkly-title="FAQ"
		>
			<Override
				slot="SectionContent"
				width="100%"
				min-width="auto"
				margin="0px 48px 0px 48px"
				md-margin="0px 16px 0px 16px"
				align-items="center"
			/>
			<Text
				font="--base"
				margin="0px 0px 10px 0px"
				text-transform="uppercase"
				lg-margin="0px 0px 6px 0px"
				quarkly-title="Title"
				letter-spacing="1px"
				color="--dark"
				opacity="0.6"
				text-align="center"
				lg-text-align="center"
			>
				We're here to help
			</Text>
			<Text
				sm-font="--headline3"
				md-font="--headline3"
				font="--headline2"
				margin="0px 0px 64px 0px"
				color="--dark"
				text-align="center"
				lg-text-align="center"
				lg-margin="0px 0px 36px 0px"
			>
				FAQ
			</Text>
			<Box
				width="100%"
				display="grid"
				grid-gap="32px"
				grid-template-columns="repeat(4, 1fr)"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
			>
				<Box>
					<Text
						text-align="center"
						md-text-align="left"
						font="--lead"
						margin="0px 0px 0px 0px"
						color="--dark"
					>
						How does Paralux authenticate luxury watches?{"\n"}
					</Text>
					<Text
						text-align="center"
						margin="16px 0px 0px 0px"
						md-text-align="left"
						sm-margin="8px 0px 0px 0px"
						font="--base"
						color="--darkL2"
						opacity="0.6"
						lg-text-align="center"
					>
						Paralux employs a comprehensive authentication process, combining certified specialists with advanced AI image recognition technology to capture every unique detail of a watch. This creates a one-of-a-kind biometric fingerprint, ensuring authenticity.
					</Text>
				</Box>
				<Box>
					<Text
						margin="0px 0px 0px 0px"
						color="--dark"
						text-align="center"
						md-text-align="left"
						font="--lead"
					>
						Can I track the authentication status of my watch on Paralux?
					</Text>
					<Text
						lg-text-align="center"
						text-align="center"
						margin="16px 0px 0px 0px"
						md-text-align="left"
						sm-margin="8px 0px 0px 0px"
						font="--base"
						color="--darkL2"
						opacity="0.6"
					>
						Yes, Paralux provides real-time tracking of the authentication status of your watch. Simply log in to your account to view the progress and updates on your authentication request.
					</Text>
				</Box>
				<Box>
					<Text
						color="--dark"
						text-align="center"
						md-text-align="left"
						font="--lead"
						margin="0px 0px 0px 0px"
					>
						What sets Paralux apart from other watch authentication services?
					</Text>
					<Text
						font="--base"
						color="--darkL2"
						opacity="0.6"
						lg-text-align="center"
						text-align="center"
						margin="16px 0px 0px 0px"
						md-text-align="left"
						sm-margin="8px 0px 0px 0px"
					>
						Unlike traditional authentication methods, Paralux offers a lifetime authentication guarantee. Can I track the authentication status of my watch on Paralux?
					</Text>
				</Box>
				<Box>
					<Text
						font="--lead"
						margin="0px 0px 0px 0px"
						color="--dark"
						text-align="center"
						md-text-align="left"
					>
						Does Paralux have Partnerships?
					</Text>
					<Text
						lg-text-align="center"
						text-align="center"
						margin="16px 0px 0px 0px"
						md-text-align="left"
						sm-margin="8px 0px 0px 0px"
						font="--base"
						color="--darkL2"
						opacity="0.6"
					>
						Yes, we partner with auction houses and wholesalers to host their collections.{" "}
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="80px 0 80px 0"
			quarkly-title="Customers"
			lg-padding="50px 0 50px 0"
			justify-content="center"
			display="none"
		>
			<Override
				slot="SectionContent"
				min-width="auto"
				margin="0px 32px 0px 32px"
				align-items="center"
				width="100%"
				md-margin="0px 16px 0px 16px"
			/>
			<Text
				color="--dark"
				text-align="center"
				lg-margin="0px 0px 6px 0px"
				text-transform="uppercase"
				lg-text-align="center"
				quarkly-title="Title"
				font="--base"
				margin="0px 0px 10px 0px"
				letter-spacing="1px"
				opacity="0.6"
			>
				They trust us
			</Text>
			<Text
				text-align="center"
				lg-text-align="center"
				sm-margin="0px 0px 48px 0px"
				sm-font="--headline3"
				md-font="--headline3"
				font="--headline2"
				margin="0px 0px 64px 0px"
				color="--dark"
			>
				Our Customers
			</Text>
			<Box
				lg-grid-template-columns="repeat(6, 1fr)"
				sm-grid-template-columns="1fr"
				display="grid"
				width="100%"
				grid-gap="32px"
				grid-template-columns="repeat(5, 1fr)"
			>
				<Box
					sm-align-items="center"
					sm-margin="0px 0px 0px 20px"
					lg-grid-column="1 / span 2"
					sm-grid-column="auto / auto"
					sm-display="flex"
				>
					<Icon
						category="di"
						icon={DiBootstrap}
						margin="0px auto 0px auto"
						size="84px"
						lg-font="64px sans-serif"
						sm-margin="0px 13px 0px 0px"
						sm-font="48px sans-serif"
					/>
					<Text
						md-margin="16px 0px 0px 0px"
						sm-margin="0px 0px 0px 0px"
						font="--base"
						color="--darkL2"
						opacity="0.6"
						lg-text-align="center"
						text-align="center"
					>
						Company Name
					</Text>
				</Box>
				<Box
					sm-align-items="center"
					sm-margin="0px 0px 0px 20px"
					lg-grid-column="3 / span 2"
					sm-grid-column="auto / auto"
					sm-display="flex"
				>
					<Icon
						size="84px"
						lg-font="64px sans-serif"
						sm-margin="0px 13px 0px 0px"
						sm-font="48px sans-serif"
						category="di"
						icon={DiBingSmall}
						margin="0px auto 0px auto"
					/>
					<Text
						text-align="center"
						md-margin="16px 0px 0px 0px"
						sm-margin="0px 0px 0px 0px"
						font="--base"
						color="--darkL2"
						opacity="0.6"
						lg-text-align="center"
					>
						Company Name
					</Text>
				</Box>
				<Box
					lg-grid-column="5 / span 2"
					sm-grid-column="auto / auto"
					sm-display="flex"
					sm-align-items="center"
					sm-margin="0px 0px 0px 20px"
				>
					<Icon
						sm-font="48px sans-serif"
						category="di"
						icon={DiCssTricks}
						margin="0px auto 0px auto"
						size="84px"
						lg-font="64px sans-serif"
						sm-margin="0px 13px 0px 0px"
					/>
					<Text
						font="--base"
						color="--darkL2"
						opacity="0.6"
						lg-text-align="center"
						text-align="center"
						md-margin="16px 0px 0px 0px"
						sm-margin="0px 0px 0px 0px"
					>
						Company Name
					</Text>
				</Box>
				<Box
					lg-grid-column="2 / span 2"
					sm-grid-column="auto / auto"
					sm-display="flex"
					sm-align-items="center"
					sm-margin="0px 0px 0px 20px"
				>
					<Icon
						icon={DiEnvato}
						margin="0px auto 0px auto"
						size="84px"
						lg-font="64px sans-serif"
						sm-margin="0px 13px 0px 0px"
						sm-font="48px sans-serif"
						category="di"
					/>
					<Text
						color="--darkL2"
						opacity="0.6"
						lg-text-align="center"
						text-align="center"
						md-margin="16px 0px 0px 0px"
						sm-margin="0px 0px 0px 0px"
						font="--base"
					>
						Company Name
					</Text>
				</Box>
				<Box
					lg-grid-column="4 / span 2"
					sm-grid-column="auto / auto"
					sm-display="flex"
					sm-align-items="center"
					sm-margin="0px 0px 0px 20px"
				>
					<Icon
						lg-font="64px sans-serif"
						sm-margin="0px 13px 0px 0px"
						sm-font="48px sans-serif"
						category="di"
						icon={DiGithubBadge}
						margin="0px auto 0px auto"
						size="84px"
					/>
					<Text
						opacity="0.6"
						lg-text-align="center"
						text-align="center"
						md-margin="16px 0px 0px 0px"
						sm-margin="0px 0px 0px 0px"
						font="--base"
						color="--darkL2"
					>
						Company Name
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			quarkly-title="USP"
			padding="75px 0 50px 0"
			lg-padding="50px 0 25px 0"
			lg-height="auto"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
				width="100%"
				margin="0px 32px 0px 32px"
			/>
			<Box
				justify-content="center"
				flex-direction="column"
				md-min-height="360px"
				min-height="480px"
				padding="36px 24px 36px 24px"
				align-items="center"
				sm-min-height="280px"
				display="flex"
				background="linear-gradient(180deg,rgba(24, 29, 34, 0.5) 0%,transparent 100%) 0 0 no-repeat,--color-lightD1 url(https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-fireworks.svg?v=2020-11-06T17:22:27.801Z) center center/cover no-repeat"
				lg-min-height="420px"
			>
				<Text
					color="--light"
					md-font="--headline3"
					margin="10px 0px 15px 0px"
					font="--headline2"
					text-align="center"
					quarkly-title="Title"
				>
					Connect With Us
				</Text>
				<Text
					opacity="0.6"
					text-align="center"
					quarkly-title="Description"
					color="--light"
					max-width="720px"
					margin="0px 0px 28px 0px"
					font="--lead"
				>
					Sign up for the waitlist exclusive access for our launch and get updates about new features!
				</Text>
				<Link
				text-align="center"
				margin="18px 0px 0px 0px"
				hover-transform="translateY(-4px)"
				href="/SignUp"
				color="--light"
				padding="8px 18px 8px 18px"
				font="--base"
				transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
				md-margin="36px 0px 0px 0px"
				text-decoration-line="initial"
				letter-spacing="0.5px"
				background="--color-primary"
				lg-margin="24px 0px 0px 0px"
			>
				Sign Up
			</Link>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="linkBox" target="_top" />
		</Components.Footer>
	</Theme>
// });
)};

export default IndexPage;