import React, { useState, useEffect} from 'react';
import theme from "theme";
import { Theme, Link, Image, Button, Section, Text, Input, Box } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override, Formspree } from "@quarkly/components";
import * as Components from "components";
import { MdMenu } from "react-icons/md";

import axios from 'axios';

// Import the functions you need from the SDKs you need
import { firebase, initializeApp } from "firebase/app";
// import { firebaseApp } from '../components/firebase'; // Import db from firebase.js

import { getDatabase, query, get, set, ref } from "firebase/database";
// import { db, storage } from "../../../utils/hooks/useFirebase";
import { getStorage } from "firebase/storage";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import {
  getDownloadURL,
  ref as storageRef,
  uploadBytes,
} from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Styling 
import signUpPageStyle from '../style/signupPageStyle';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => signUpPageStyle(theme));

// import signUpPageStyle from '../style/signUp.js'; // Import CSS file for styling


//TODO: if local (no quotes), if production then quotes 
// const firebaseConfig = {
//     apiKey: "process.env.REACT_APP_FIRE_API_KEY",
//     authDomain: "process.env.REACT_APP_FIRE_AUTH_DOMAIN",
//     databaseURL: "process.env.REACT_APP_FIRE_DATABASE_URL",
//     projectId: "process.env.REACT_APP_FIRE_PROJECT_ID",
//     storageBucket: "process.env.REACT_APP_FIRE_STORAGE_BUCKET",
//     messagingSenderId: "process.env.REACT_APP_FIRE_MESSAGING_SENDER_ID",
//     appId: "process.env.REACT_APP_FIRE_APP_ID"
// };

//Local
// const firebaseConfig = {
//     apiKey: process.env.REACT_APP_FIRE_API_KEY,
//     authDomain: process.env.REACT_APP_FIRE_AUTH_DOMAIN,
//     databaseURL: process.env.REACT_APP_FIRE_DATABASE_URL,
//     projectId: process.env.REACT_APP_FIRE_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_FIRE_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_FIRE_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_FIRE_APP_ID
// };
console.log("firebaseConfig");
// console.log(firebaseConfig);
// const firebaseApp = initializeApp(firebaseConfig);
// const auth = getAuth(firebaseApp);

const SignupForm = () => {
	const classes = useStyles();
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
  	const [submitted, setSubmitted] = useState(false);

	// const database = getDatabase(firebaseApp);

	// const readData = async () => {
	// 	console.log('READ DATE ');
	// 	try {
	// 	  const snapshot = await get(ref(database, '/waitlist'));
	// 	  const data = snapshot.val();
	// 	  console.log('Data from Firebase:', data);
	// 	  return data;
	// 	} catch (error) {
	// 	  console.error('Error reading data:', error);
	// 	}
	//   };
	  
	  const handleSubmit2 = async (e) => {
		console.log("Testing");
	  }

	  const handleSubmit = async (e) => {
		e.preventDefault();
		console.log('Email', email);
		try {
			// Add data to Firebase database
			const type = "waitlist";
			const date = new Date();
			const month = date.getMonth() + 1;
			const day = date.getDate();
			const year = date.getFullYear();

			const formattedDate = `${month}-${day}-${year}`;
			const currDate = new Date().toLocaleDateString();
			console.log(currDate);
			console.log(formattedDate);
			const data = {
				email: email,
				date: currDate
			}
			const sanitizedEmail = email.replace(/[.#$[\]]/g, '_');
			const key = `${type}::${sanitizedEmail}`;
			// const key = formattedDate;
			// await set(ref(database, 'waitlist/' + key), data);
			// console.log('Data from Firebase:', data);
			setEmail('');
			setSubmitted(true);

			//MailJet called from backend server 
			await axios.post('http://localhost:8000/api/addToWaitlist', { email: email });

		} catch (error) {
			console.error('Error adding document: ', error);
		}
		// Clear form fields and set submitted flag
		setName('');
		setEmail('');
		setSubmitted(true);
	  };


	return ( 
	<Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"SignUp"} />
		<Helmet>
			<title>
				Sign Up | Paralux
			</title>
			<meta name={"description"} content={"It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference."} />
			<meta property={"og:title"} content={"Contact | Website Example"} />
			<meta property={"og:description"} content={"It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/website-example-quarkly.png?v=2020-11-05T19:48:01.806Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} />
		</Helmet>
		<Section align-items="center" justify-content="center" padding="16px 0 16px 0" quarkly-title="Header">
			<Override
				slot="SectionContent"
				grid-template-columns="repeat(3, 1fr)"
				md-grid-template-columns="repeat(2, 1fr)"
				margin="0px 32px 0px 32px"
				width="100%"
				md-margin="0px 16px 0px 16px"
				display="grid"
			/>
			<Link href="/index" position="relative" transition="opacity 200ms ease" quarkly-title="Link">
				<Image
					src="https://uploads.quarkly.io/6617165c6bbd200023699c9b/images/Logo_Black.jpg?v=2024-04-12T14:40:28.861Z"
					width="120px"
					z-index="3"
					srcSet="https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Logo_Black.jpg?v=2024-04-12T14%3A40%3A28.861Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Logo_Black.jpg?v=2024-04-12T14%3A40%3A28.861Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Logo_Black.jpg?v=2024-04-12T14%3A40%3A28.861Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Logo_Black.jpg?v=2024-04-12T14%3A40%3A28.861Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Logo_Black.jpg?v=2024-04-12T14%3A40%3A28.861Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Logo_Black.jpg?v=2024-04-12T14%3A40%3A28.861Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6617165c6bbd200023699c9b/images/Logo_Black.jpg?v=2024-04-12T14%3A40%3A28.861Z&quality=85&w=3200 3200w"
					sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
				/>
			</Link>
			<Components.BurgerMenu md-display="flex" md-align-items="center" md-justify-content="flex-end">
				<Override
					slot="menu"
					lg-transform="translateY(0px) translateX(0px)"
					lg-transition="transform 400ms ease 0s"
					md-position="fixed"
					display="flex"
					md-left={0}
					md-top={0}
					padding="0px 0 0px 0"
					justify-content="center"
					md-width="100%"
					md-height="100%"
					align-items="center"
				>
					<Override
						slot="item"
						text-transform="uppercase"
						text-align="center"
						padding="8px 20px 8px 20px"
						md-padding="16px 40px 16px 40px"
						display="inline-block"
					/>
					<Override slot="item-404" lg-display="none" display="none" />
					<Override slot="item-index" lg-display="none" display="none" />
					<Override
						slot="link"
						transition="opacity .15s ease 0s"
						md-color="--dark"
						md-opacity=".5"
						md-hover-opacity="1"
						md-active-opacity="1"
						color="--dark"
						opacity=".5"
						font="--base"
						letter-spacing="0.5px"
						text-transform="initial"
						md-transition="opacity .15s ease 0s"
						hover-opacity="1"
						md-font="16px/24px sans-serif"
						text-decoration-line="initial"
					/>
					<Override
						slot="link-active"
						md-opacity="1"
						md-cursor="default"
						opacity="1"
						color="--primary"
						cursor="default"
					/>
				</Override>
				<Override slot="icon,icon-close" category="md" icon={MdMenu} />
				<Override
					slot="icon"
					size="36px"
					md-right="0px"
					md-position="relative"
					category="md"
					icon={MdMenu}
				/>
				<Override
					slot="menu-open"
					md-top={0}
					md-bottom={0}
					md-display="flex"
					md-flex-direction="column"
					md-align-items="center"
					md-justify-content="center"
				/>
				<Override slot="icon-open" md-position="fixed" md-top="18px" md-right="18px" />
			</Components.BurgerMenu>
			<Button
				white-space="nowrap"
				transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
				padding="8px 18px 8px 18px"
				background="--color-primary"
				z-index="5"
				md-display="none"
				border-radius="0px"
				hover-transform="translateY(-4px)"
				justify-self="end"
				font="--base"
				letter-spacing="0.5px"
			>
				Contact Us
			</Button>
		</Section>
		<Section quarkly-title="Hero" padding="25px 0 75px 0" lg-padding="25px 0 25px 0" justify-content="center">
			<Override
				slot="SectionContent"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Box
				align-items="center"
				justify-content="center"
				background="conic-gradient(at center,rgba(0,0,0,0) 92.4%,rgba(0,0,0,1) 100%),url(),linear-gradient(180deg,rgba(155, 108, 252, 0.15) 0%,transparent 100%) 0 0 no-repeat,--color-darkL2"
				md-min-height="360px"
				sm-min-height="180px"
				width="100%"
				min-height="480px"
				display="flex"
				align-self="auto"
				flex-direction="column"
				height="800px"
			>
				<Text
					align-items="center"
					justify-content="center"
					sm-font="normal 900 42px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					text-align="center"
					font="--headline1"
					display="flex"
					color="--light"
					quarkly-title="Title"
					sm-text-align="center"
					md-font="--headline2"
					lg-font="--headline2"
					margin="0px 0px 0px 0px"
				>
					Paralux is{" "}
					<br />
					Launching Soon
				</Text>
				<Section
					lg-padding="50px 0 5px 0"
					lg-height="auto"
					justify-content="center"
					quarkly-title="USP"
					padding="75px 0 50px 0"
					align-items="center"
				>
					<Override
						slot="SectionContent"
						min-width="auto"
						width="100%"
						margin="0px 32px 0px 32px"
						md-margin="0px 16px 0px 16px"
						align-items="center"
						display="block"
						padding="0px 36px 0px 36px"
					/>
					<Box
						border-style="solid"
						border-color="--color-lightD2"
						flex-direction="column"
						border-width={0}
						justify-content="flex-start"
						align-items="center"
						display="flex"
						align-self="center"
						padding="30px px 30px 36px"
						color="#ffffff"
					>
						<Text
							quarkly-title="Title"
							md-font="--headline3"
							font="--headline2"
							margin="0px 0px 15px 0px"
							display="inline-block"
						>
							Sign Up{" "}
						</Text>
						<Text
							color="#ffffff"
							md-font="--base"
							font="--lead"
							margin="0px 0px 28px 0px"
							display="inline-block"
							quarkly-title="Description"
							text-align="center"
						>
							Join our waitlist now for exclusive access for our launch and get updates about new features!
						</Text>
					</Box>
				</Section>
				<div className={classes.signUpFormContainer}>
					{submitted ? (
								<p className={classes.formDescription}>Thank you for joining the waitlist!</p>
							) : (
					<form onSubmit={handleSubmit} className={classes.form}>
						<div className={classes.formGroup}>
							<input 
							type="email" 
							value={email} 
							onChange={(e) => setEmail(e.target.value)} 
							placeholder="Email"
							required 
							className={classes.formInput}
							/>
						</div>
					<Button type="submit" className={classes.formButton}>Join Waitlist</Button>
					</form>
						)}
				</div>
			</Box>
		</Section>
		<Components.Footer />
	</Theme>
)};

export default SignupForm;
