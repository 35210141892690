import React from "react";
import theme from "theme";
import { Theme, Link, Text, Box, Section, Icon, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
import { FaRegGem, FaWpforms, FaRegLifeRing, FaRegStar } from "react-icons/fa";
import { MdShoppingBasket } from "react-icons/md";
import { AiOutlineThunderbolt } from "react-icons/ai";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				About | Paralux
			</title>
			<meta name={"description"} content={"It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference."} />
			<meta property={"og:title"} content={"About | Website Example"} />
			<meta property={"og:description"} content={"It all begins with an idea. Maybe you want to launch a business. Maybe you want to turn a hobby into something bigger. Or maybe you have a creative project to share with the world. Whatever it is, the way you tell your story online can make all the difference."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/website-example-quarkly.png?v=2020-11-05T19:48:01.806Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} />
		</Helmet>
		<Components.NavBarHeader>
			<Override
				slot="button"
				href="/SignUp"
				display="inline-block"
				width="fit-content"
				justify-self="end"
			/>
		</Components.NavBarHeader>
		<Section quarkly-title="Hero" padding="25px 0 75px 0" lg-padding="25px 0 25px 0" justify-content="center">
			<Override
				slot="SectionContent"
				min-width="auto"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
			/>
			<Box
				align-items="center"
				display="flex"
				justify-content="center"
				background="url(),radial-gradient(at center,--color-darkL2 23.3%,rgba(0,0,0,0) 82.4%),linear-gradient(180deg,rgba(155, 108, 252, 0.15) 0%,transparent 100%) 0 0 no-repeat,--color-darkL2"
				md-min-height="360px"
				sm-min-height="180px"
				width="100%"
				min-height="480px"
			>
				<Text
					font="--headline1"
					margin="0px 0px 0px 0px"
					quarkly-title="Title"
					sm-text-align="center"
					text-align="center"
					md-font="--headline2"
					display="flex"
					align-items="center"
					color="--light"
					justify-content="center"
					sm-font="normal 900 42px/1.2 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
					lg-font="--headline2"
				>
					About Us.
				</Text>
			</Box>
		</Section>
		<Section
			padding="90px 0px 100px 0px"
			background="--color-lightD1"
			border-style="solid"
			lg-padding="50px 0px 50px 0px"
			justify-content="center"
			quarkly-title="About"
			box-sizing="border-box"
			border-width="1px 0px 1px 0px"
			md-padding="50px 0px 50px 0px"
			sm-padding="0px 0 0px 0"
			border-color="--color-lightD2"
		>
			<Override
				slot="SectionContent"
				sm-padding="24px 0px 24px 0px"
				width="100%"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
				align-items="center"
				margin="0px 32px 0px 32px"
			/>
			<Text
				opacity="0.6"
				letter-spacing="1px"
				margin="0px 0px 10px 0px"
				lg-text-align="center"
				font="--base"
				color="--dark"
				lg-margin="0px 0px 6px 0px"
				quarkly-title="Title"
				text-transform="uppercase"
				text-align="center"
			>
				Who we are
			</Text>
			<Text
				letter-spacing="1px"
				color="--dark"
				text-align="center"
				width="85%"
				lg-font="--lead"
				sm-font="--base"
				font="--headline3"
				margin="0px 0px 0px 0px"
			>
				Experts in luxury watch authentication, redefining trust.
			</Text>
		</Section>
		<Section
			box-sizing="border-box"
			padding="100px 0px 100px 0px"
			quarkly-title="Our Services"
			xl-padding="100px 0px 100px 0px"
			lg-padding="50px 0px 50px 0px"
			justify-content="center"
		>
			<Override
				slot="SectionContent"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
				align-items="center"
			/>
			<Text
				letter-spacing="1px"
				lg-margin="0px 0px 6px 0px"
				quarkly-title="Title"
				margin="0px 0px 10px 0px"
				color="--dark"
				opacity="0.6"
				text-align="center"
				text-transform="uppercase"
				lg-text-align="center"
				font="--base"
			>
				WHAT WE DO BEST
			</Text>
			<Text
				lg-margin="0px 0px 48px 0px"
				md-font="--headline3"
				sm-margin="0px 0px 36px 0px"
				font="--headline2"
				margin="0px 0px 72px 0px"
				color="--dark"
				text-align="center"
				lg-text-align="center"
			>
				Our Services
			</Text>
			<Box
				grid-template-columns="repeat(3, 1fr)"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				width="100%"
				display="grid"
				grid-gap="32px"
			>
				<Box display="flex" flex-direction="column" align-items="center" justify-content="center">
					<Icon
						category="fa"
						icon={FaRegGem}
						size="82px"
						margin="0px 0px 19px 0px"
						font="36px sans-serif"
						color="--primary"
					/>
					<Text color="--darkL2" font="--lead" margin="0px 0px 10px 0px" display="inline-block">
						High Quality
					</Text>
					<Text
						lg-text-align="center"
						text-align="center"
						font="--base"
						margin="0px 0px 0px 0px"
						display="inline-block"
						opacity="0.6"
						color="--darkL2"
					>
						Premium authentication services ensuring top-notch quality.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" align-items="center" justify-content="center">
					<Icon
						category="fa"
						icon={FaWpforms}
						size="82px"
						margin="0px 0px 19px 0px"
						font="36px sans-serif"
						color="--primary"
					/>
					<Text font="--lead" margin="0px 0px 10px 0px" display="inline-block" color="--darkL2">
						Certification of Authentication
					</Text>
					<Text
						font="--base"
						margin="0px 0px 0px 0px"
						display="inline-block"
						opacity="0.6"
						color="--darkL2"
						lg-text-align="center"
						text-align="center"
					>
						Guaranteed authentication certification for every watch.
					</Text>
				</Box>
				<Box justify-content="center" display="flex" flex-direction="column" align-items="center">
					<Icon
						font="36px sans-serif"
						color="--primary"
						category="md"
						icon={MdShoppingBasket}
						size="82px"
						margin="0px 0px 19px 0px"
					/>
					<Text font="--lead" margin="0px 0px 10px 0px" display="inline-block" color="--darkL2">
						MarketPlace
					</Text>
					<Text
						text-align="center"
						font="--base"
						margin="0px 0px 0px 0px"
						display="inline-block"
						opacity="0.6"
						color="--darkL2"
						lg-text-align="center"
					>
						Explore our curated marketplace for luxury timepieces.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" align-items="center" justify-content="center">
					<Icon
						category="fa"
						icon={FaRegLifeRing}
						size="82px"
						margin="0px 0px 19px 0px"
						font="36px sans-serif"
						color="--primary"
					/>
					<Text font="--lead" margin="0px 0px 10px 0px" display="inline-block" color="--darkL2">
						Best Support
					</Text>
					<Text
						opacity="0.6"
						color="--darkL2"
						lg-text-align="center"
						text-align="center"
						font="--base"
						margin="0px 0px 0px 0px"
						display="inline-block"
					>
						Dedicated customer support for unparalleled assistance.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" align-items="center" justify-content="center">
					<Icon
						font="36px sans-serif"
						color="--primary"
						category="fa"
						icon={FaRegStar}
						size="82px"
						margin="0px 0px 19px 0px"
					/>
					<Text color="--darkL2" font="--lead" margin="0px 0px 10px 0px" display="inline-block">
						Historic Verification
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						display="inline-block"
						opacity="0.6"
						color="--darkL2"
						lg-text-align="center"
						text-align="center"
						font="--base"
					>
						Public and private history levels to know the complete history of the watch.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" align-items="center" justify-content="center">
					<Icon
						color="--primary"
						category="ai"
						icon={AiOutlineThunderbolt}
						size="82px"
						margin="0px 0px 19px 0px"
						font="36px sans-serif"
					/>
					<Text font="--lead" margin="0px 0px 10px 0px" display="inline-block" color="--darkL2">
						Partnerships
					</Text>
					<Text
						font="--base"
						margin="0px 0px 0px 0px"
						display="inline-block"
						opacity="0.6"
						color="--darkL2"
						lg-text-align="center"
						text-align="center"
					>
						Collaborating with trusted partners for excellence.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section
			padding="80px 0px 80px 0px"
			quarkly-title="About"
			background="--color-lightD1"
			lg-padding="50px 0px 50px 0px"
			justify-content="center"
			box-sizing="border-box"
			border-width="1px 0px 1px 0px"
			border-style="solid"
			border-color="--color-lightD2"
			height="500px"
			sm-height="fit-content"
		>
			<Override
				slot="SectionContent"
				align-items="center"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Box
				display="grid"
				grid-gap="32px"
				grid-template-columns="repeat(3, 1fr)"
				width="100%"
				lg-grid-template-columns="1fr"
				height="100%"
			>
				<Text
					color="--darkL2"
					lg-margin="0px 0px 0px 0px"
					md-font="--lead"
					font="--headline3"
					margin="0px 0px 10px 0px"
					display="inline-block"
					height="50%"
				>
					Our Story
				</Text>
				<Text
					display="inline-block"
					opacity="0.6"
					color="--darkL2"
					lg-text-align="left"
					text-align="left"
					font="--base"
					margin="0px 0px 0px 0px"
				>
					At Paralux, our journey began from a shared frustration with the lack of trust and transparency in the luxury watch market. As avid watch enthusiasts ourselves, we understand the disappointment of purchasing a timepiece only to discover doubts about its authenticity or history. It became clear to us that there was a significant gap in the industry – a need for a standardized authentication process that offers complete transparency and peace of mind to buyers and sellers alike.
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					display="inline-block"
					opacity="0.6"
					color="--darkL2"
					lg-text-align="left"
					text-align="left"
					font="--base"
				>
					Driven by our passion for watches and our commitment to integrity, we founded Paralux with a mission to revolutionize watch authentication. Unlike traditional methods that leave room for doubt, our innovative approach combines cutting-edge technology with expert craftsmanship to provide a seamless and trustworthy authentication experience. We believe that every watch deserves to have its history preserved and its authenticity guaranteed, and that's what sets Paralux apart. With Paralux, you can be confident that every timepiece tells a genuine story, backed by our unwavering dedication to authenticity and excellence.
				</Text>
			</Box>
		</Section>
		<Section
			padding="75px 0 50px 0"
			lg-padding="50px 0 25px 0"
			lg-height="auto"
			justify-content="center"
			quarkly-title="USP"
		>
			<Override
				slot="SectionContent"
				width="100%"
				margin="0px 32px 0px 32px"
				md-margin="0px 16px 0px 16px"
				min-width="auto"
			/>
			<Box
				min-height="480px"
				display="flex"
				justify-content="center"
				background="linear-gradient(180deg,rgba(24, 29, 34, 0.5) 0%,transparent 100%) 0 0 no-repeat,--color-lightD1 url(https://uploads.quarkly.io/5f44d0da669357001e60ed14/images/default-website-fireworks.svg?v=2020-11-06T17:22:27.801Z) center center/cover no-repeat"
				lg-min-height="420px"
				sm-min-height="280px"
				padding="36px 24px 36px 24px"
				align-items="center"
				flex-direction="column"
				md-min-height="360px"
			>
				<Text
					margin="10px 0px 15px 0px"
					font="--headline2"
					text-align="center"
					quarkly-title="Title"
					color="--light"
					md-font="--headline3"
				>
					Connect with Us!
				</Text>
				<Text
					margin="0px 0px 28px 0px"
					font="--lead"
					opacity="0.6"
					text-align="center"
					quarkly-title="Description"
					color="--light"
					max-width="720px"
				>
					Sign up for the waitlist exclusive access for our launch and get updates about new features!
				</Text>
				<Link
				text-align="center"
				margin="18px 0px 0px 0px"
				hover-transform="translateY(-4px)"
				href="/SignUp"
				color="--light"
				padding="8px 18px 8px 18px"
				font="--base"
				transition="transform --transitionDuration-fast --transitionTimingFunction-easeInOut 0s"
				md-margin="36px 0px 0px 0px"
				text-decoration-line="initial"
				letter-spacing="0.5px"
				background="--color-primary"
				lg-margin="24px 0px 0px 0px"
			>
				Sign Up
			</Link>
			</Box>
		</Section>
		<Components.Footer />
	</Theme>;
});